
const API_HOST = "https://sapi.sanatika.com";

function throwUIError(text) {
    alert(text);
}

let currentLoader = false;

function httpPostWT(theUrl, body) {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open("POST", theUrl, false); // false for synchronous request

    xmlHttp.setRequestHeader('Content-Type', 'application/json');
    xmlHttp.send(JSON.stringify(body));
    return xmlHttp.responseText;
}

async function apiJsonPost(endpoint, body) {
    let response;
    try {
        response = await fetch(API_HOST + "/api/" + endpoint, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(body), // body data type must match "Content-Type" header
    });
    } catch (error) {
        if(error.toString() === "TypeError: Load failed"){
            return "429FAIL";
        }
    }
    
    
   /// let rawResponse = JSON.parse(httpPostWT(API_HOST + "/api/" + endpoint, body));

    return response.json();

}

function toggleLoader() {
    if (currentLoader) {
        document.querySelector('.login-icon').classList.remove('dn');
        currentLoader = true;
    } else {
        document.querySelector('.login-icon').classList.add('dn');
        currentLoader = false;
    }
}

async function loginAction() {
    new Promise(() => {
        setTimeout(() => {
            toggleLoader();
        }, 1)
    })
    let uname = document.querySelector('#uname').value;
    let password = document.querySelector('#pw').value;


    let securePassword = password;

    let pcBody = {
        username: uname,
        pwd: securePassword
    }
    let respo = await apiJsonPost('login', pcBody);
    console.log(respo);
    if(respo === "429FAIL") {
        throwUIError(`Giriş başarısız. Bu hata kısa sürede çok fazla giriş denemesi yaptığınızda oluşabilir. Lütfen biraz bekleyin ve tekrardan giriş yapmayı deneyin.\n\nHata Kodu: LOADFAIL`);
    }
    else if (respo.success) {
        localStorage.setItem('AppAccessToken', respo.accessToken);
        window.location.href = '/';

    } else if(respo.helpfulText) {
        throwUIError(`${respo.helpfulText}\n\nHata Kodu: ${respo.result}`);
    }else{
        throwUIError(`Giriş Başarısız. Lütfen bilgilerinizi kontrol ederek tekrar giriş yapmayı deneyin. Hata Kodu: ${respo.result}`);
    }



    toggleLoader();
}
document.querySelector('.login-btn').addEventListener('click', () => {
    loginAction();
})
document.querySelector('#pw').addEventListener("keyup", function(event) {
    if (event.keyCode === 13) {
        loginAction();
    }
});
